import React from "react";
import "./CelebrityList.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

function CelebrityList({ celebrities }) {
  if (!celebrities || celebrities.length === 0) {
    return <div>No celebrities to display</div>;
  }
  return (
    <div className="celebContainer">
      {celebrities.map((celebrity, index) => (
          <div key={index} className="celebCard">
            {celebrity.displayImage && (
              <img src={celebrity.displayImage} alt={`${celebrity.name}`} />
            )}
            <h3>{celebrity?.name}</h3>
            <h3>{celebrity?.brand}</h3>
            <p>Field: {celebrity.field}</p>
            {celebrity.stance && (
              <p
                style={{
                  color:
                    celebrity.stance === "Pro Palestine" ? "#1dd3b0" :
                    (celebrity.stance === "No statement" || celebrity.stance === "Pro Israel") ? "#f25f5c" : "black"
                }}
              >
                Stance: {celebrity.stance}
              </p>
            )}
            {celebrity.location && <p>Location: {celebrity.location}</p>}
            {celebrity.socialMediaLinks && celebrity.socialMediaLinks.instagram && (
              <div className="sMediaContainer">
                <p>
                  Social Media:
                  <a
                    href={celebrity.socialMediaLinks.instagram}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon className="insta" icon={faInstagram} size="2x" />
                  </a>
                </p>
              </div>
            )}
            <a className="source" href={celebrity.source}>Source</a>
          </div>
      ))}
    </div>
  );
}

export default CelebrityList;
