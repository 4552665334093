import React, { useState, useEffect } from 'react';
import CelebrityList from './CelebrityList';
import './LandingPage.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSearch, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import MoonLoader from "react-spinners/PuffLoader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

library.add(faSearch);

function LandingPage() {
  const [celebrities, setCelebrities] = useState([]);
  const [visibleCelebrities, setVisibleCelebrities] = useState([]);
  const [pageSize] = useState(10); // Number of celebrities to load initially and when scrolling
  const [pageIndex, setPageIndex] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true)
  const [endOfPage, setEndOfPage] = useState(false); // Add endOfPage state

  console.log('version 2.2')

  useEffect(() => {
    // Fetch data from your server
    async function fetchData() {
      try {
        const response = await fetch('/get-celebrities');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
    
        // Sort the data by timestamp in descending order (most recent first)
        const sortedCelebrities = data?.celebrities.sort((a, b) => {
          if (a.timestamp && b.timestamp) {
            return new Date(b.timestamp) - new Date(a.timestamp);
          } else if (a.timestamp) {
            return -1; // Place the entry with timestamp first
          } else if (b.timestamp) {
            return 1; // Place the entry with timestamp first
          }
          return 0; // Both entries have no timestamp
        });
    
        setCelebrities(sortedCelebrities);
        console.log(sortedCelebrities);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    

    fetchData();
  }, []);

  useEffect(() => {
    // Load the initial set of celebrities
    const startIndex = 0;
    const endIndex = pageSize;
    const initialCelebrities = celebrities.slice(startIndex, endIndex);
    setVisibleCelebrities(initialCelebrities);
    setPageIndex(1);
  }, [celebrities, pageSize]);

  // const handleScroll = () => {
  //   if (
  //     window.innerHeight + document.documentElement.scrollTop ===
  //     document.documentElement.offsetHeight
  //   ) {
  //     // Load more celebrities when scrolling to the bottom
  //     const startIndex = pageIndex * pageSize;
  //     const endIndex = startIndex + pageSize;
  //     const newCelebrities = celebrities.slice(startIndex, endIndex);
  //     setVisibleCelebrities((prevCelebrities) => [...prevCelebrities, ...newCelebrities]);
  //     setPageIndex(pageIndex + 1);
  //   }
  // };

  const handleScroll = () => {
    if (endOfPage) {
      return; // Do nothing if already at the end of the page
    }

    if (
      window.innerHeight + document.documentElement.scrollTop ===
      document.documentElement.offsetHeight
    ) {
      // Load more celebrities when scrolling to the bottom
      const startIndex = pageIndex * pageSize;
      const endIndex = startIndex + pageSize;
      const newCelebrities = celebrities.slice(startIndex, endIndex);

      if (newCelebrities.length === 0) {
        // If no more data to load, set endOfPage to true
        setEndOfPage(true);
      } else {
        setVisibleCelebrities((prevCelebrities) => [...prevCelebrities, ...newCelebrities]);
        setPageIndex(pageIndex + 1);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [pageIndex, celebrities]);

  // Handle search input change
  const handleSearchChange = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);

    // Filter the visible celebrities based on the search term
    const filteredCelebrities = celebrities.filter((celebrity) =>
      celebrity.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setVisibleCelebrities(filteredCelebrities.slice(0, pageSize));
  };

  return (
    <div className='bottom'>
      <div className='para'>
      <h1>We're on a mission to document and raise awareness about influencers and celebrities who are using their voice to take a stance on Palestine. We believe that silence can be as powerful as speech, and our goal is to create a comprehensive list of those who are making their voices heard, as well as those who choose to remain silent.</h1>

      </div>
      <input
        className='searchBar'
        type="text"
        placeholder="Search celebrity"
        value={searchTerm}
        onChange={handleSearchChange}
        />
        {loading && 
      <MoonLoader className='moonLoader'
        loading={loading}
        color={"#eb5e28"}
        size={100}
        aria-label="Loading Spinner"
        data-testid="loader"
      />}
      <CelebrityList celebrities={visibleCelebrities} />
      {endOfPage ? <p>End of Page</p> : <span><FontAwesomeIcon icon={faArrowDown} /> <p>Load more</p></span>}
    </div>
  );
}

export default LandingPage;
