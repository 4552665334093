import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

function ProfilePage() {
  const { id } = useParams();
  const [celebrity, setCelebrity] = useState(null);

  useEffect(() => {
    // Fetch the celebrity's data using the 'id' parameter and set it in 'celebrity' state
    // You can make an API request here or use your data source.
  }, [id]);

  if (!celebrity) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h2>{celebrity.name}'s Profile</h2>
      {/* Display the celebrity's profile details here */}
    </div>
  );
}

export default ProfilePage