import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './AddCelebrityForm.css';
import SubmissionModal from './SubmissionModal'; // Import the SubmissionModal component
import './SubmissionModal.css'; // Import the CSS for the modal

function AddCelebrityForm() {
  const [celebrityName, setCelebrityName] = useState('');
  const [celebrityField, setCelebrityField] = useState('');
  const [isModalOpen, setModalOpen] = useState(false); // State to control the modal;
  const [error, setError] = useState(false)

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Create a data object to send to the server
    const formData = {
      celebrityName,
      celebrityField,
      timestamp: new Date().toISOString(),
    };
  
    // Send the data to the server
    try {
      const response = await fetch('https://us-central1-palstance-c0189.cloudfunctions.net/api/submit-form', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
  
      if (response.ok) {
        // Handle a successful form submission, e.g., navigate to the main page
        console.log('Form submitted successfully' + celebrityName, celebrityField);
        setModalOpen(true);
        // Add code here to navigate back to the main page or display a success message to the user.
      } else {
        // Handle any errors from the server
        setError(true);
        console.error('Form submission failed');
      }
    } catch (error) {
      console.error('Error sending the form data:', error);
    }
  };

  // Define functions to handle modal actions
  const handleCloseModal = () => setModalOpen(false);
  const handleGoToHome = () => {
      // Close the modal
      handleCloseModal();

      // Navigate to the home page
      navigate('/'); // Update the path as needed
  };

  const handleFillAnother = () => {
    // Clear the form fields or perform any necessary actions
    setCelebrityName('');
    setCelebrityField('');
    handleCloseModal();
  };
  
  console.log('v2.1')

  return (
    <div className='formContainer'>
      <h2>Add a Celebrity</h2>
      <form action="/submit-form" method="POST" onSubmit={handleSubmit}>
        <label>
          <input
            type="text"
            name="celebrityName" // Add the name attribute
            placeholder="Celebrity Name:"
            value={celebrityName}
            onChange={(e) => setCelebrityName(e.target.value)}
          />
        </label>
        <label>
          <input 
            type="text"
            name="celebrityField" // Add the name attribute
            placeholder="Additional information:"
            value={celebrityField}
            onChange={(e) => setCelebrityField(e.target.value)}
          />
        </label>
        <button className='submitButton' type="submit">Submit Celebrity for review</button>
      </form>
      <div>
        {error && <h2>There has been an error submitting your request</h2>}
      </div>
      {/* Render the SubmissionModal component */}
      <SubmissionModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onGoToHome={handleGoToHome}
        onFillAnother={handleFillAnother}
      />
    </div>
  );
}

export default AddCelebrityForm;
