import React, { useState, useEffect } from 'react';
import CelebrityList from './CelebrityList'; // Assuming that CelebrityList is a component you've imported
import './Brands.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import MoonLoader from "react-spinners/PuffLoader";

library.add(faSearch);

function Brands() {
  const [brands, setBrands] = useState([]); // Replace celebrities with brands
  const [visiblebrands, setVisiblebrands] = useState([]);
  const [pageSize] = useState(10); // Number of brands to load initially and when scrolling
  const [pageIndex, setPageIndex] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // Fetch data from your server
    async function fetchData() {
      try {
        const response = await fetch('https://us-central1-palstance-c0189.cloudfunctions.net/api/brands');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
    
        // Sort the data by timestamp in descending order (most recent first)
        const sortedCelebrities = data?.celebrities.sort((a, b) => {
          if (a.timestamp && b.timestamp) {
            return new Date(b.timestamp) - new Date(a.timestamp);
          } else if (a.timestamp) {
            return -1; // Place the entry with timestamp first
          } else if (b.timestamp) {
            return 1; // Place the entry with timestamp first
          }
          return 0; // Both entries have no timestamp
        });
    
        setBrands(sortedCelebrities);
        console.log(sortedCelebrities);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
      

    fetchData();
  }, []);

  useEffect(() => {
    // Load the initial set of brands
    const startIndex = 0;
    const endIndex = pageSize;
    const initialbrands = brands.slice(startIndex, endIndex);
    setVisiblebrands(initialbrands);
    setPageIndex(1);
  }, [brands, pageSize]);

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop ===
      document.documentElement.offsetHeight
    ) {
      // Load more brands when scrolling to the bottom
      const startIndex = pageIndex * pageSize;
      const endIndex = startIndex + pageSize;
      const newbrands = brands.slice(startIndex, endIndex);
      setVisiblebrands((prevbrands) => [...prevbrands, ...newbrands]);
      setPageIndex(pageIndex + 1);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [pageIndex, brands]);

  // Handle search input change
  const handleSearchChange = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);

    // Filter the visible brands based on the search term
    const filteredbrands = brands.filter((brand) =>
      brand.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setVisiblebrands(filteredbrands.slice(0, pageSize));
  };

  return (
    <div className='bottom'>
      <div className='para'>
        <h1>We're on a mission to document and raise awareness about influencers and brands who are using their voice to take a stance on Palestine. We believe that silence can be as powerful as speech, and our goal is to create a comprehensive list of those who are making their voices heard, as well as those who choose to remain silent.</h1>
      </div>
      <input
        className='searchBar'
        type="text"
        placeholder="Search brand"
        value={searchTerm}
        onChange={handleSearchChange}
      />
      {loading && 
        <MoonLoader className='moonLoader'
          loading={loading}
          color={"#eb5e28"}
          size={100}
          aria-label="Loading Spinner"
          data-testid="loader"
        />}
      <CelebrityList celebrities={brands} /> {/* Pass brands data to the CelebrityList component */}
    </div>
  );
}

export default Brands;
