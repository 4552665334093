import React, { useEffect, useState } from "react";
import "./AdminPage.css";

function AdminPage() {
  const [celebrities, setCelebrities] = useState([]);

  // Define a function to fetch data from your server
  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://us-central1-palstance-c0189.cloudfunctions.net/api/admin"
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      const fetchedCelebrities = data?.celebrities || [];
      setCelebrities(fetchedCelebrities);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    // Fetch data when the component mounts
    fetchData();
  }, []);

  // Define a function to handle deleting a celebrity
  const handleDelete = async (id) => {
    try {
      const response = await fetch(
        `https://us-central1-palstance-c0189.cloudfunctions.net/api/delete-celebrity/${id}`,
        {
          method: "DELETE",
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // After successful deletion, refresh the data by fetching it again
      fetchData();
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  return (
    <div>
      AdminPage
      {celebrities.map((celebrity, index) => (
        <div key={index} className="celebCard">
          <div className="celebCard">
            <h3>{celebrity.name}</h3>
            <p>Field: {celebrity.field} </p>
          </div>
          <button
            className="delete"
            onClick={() => handleDelete(celebrity._id)}
          >
            Delete
          </button>
        </div>
      ))}
    </div>
  );
}

export default AdminPage;
