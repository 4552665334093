import React from 'react';

function SubmissionModal({ isOpen, onClose, onGoToHome, onFillAnother }) {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Submission Successful</h2>
        <p>Your celebrity has been submitted for review.</p>
        <div className="modal-buttons">
          <button onClick={onGoToHome}>Go to Home</button>
          <button onClick={onFillAnother}>Fill Out Another</button>
        </div>
      </div>
    </div>
  );
}

export default SubmissionModal;
