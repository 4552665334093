import {React, useEffect} from 'react';
import { Route, Routes, useLocation } from 'react-router-dom'; // Import the necessary components
import './App.css';
import LandingPage from './components/LandingPage';
import AddCelebrityForm from './components/AddCelebrityForm';
import Header from './components/Header';
import AdminPage from './components/AdminPage';
import AdminAdd from './components/AdminAdd';
import ReactGA from 'react-ga';
import Brands from './components/Brands';
import ProfilePage from './components/ProfilePage';

ReactGA.initialize('G-31FGB9Y465');

function App() {

  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return (
    
      <div className="App">
        <Header/>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/add-celebrity" element={<AddCelebrityForm />} />
          <Route path="/brands" element={<Brands />} />
          <Route path="/admin" element={<AdminPage />} />
          <Route path="/adminAdd" element={<AdminAdd />} />
          <Route path="/profile/:id" component={<ProfilePage/>} />
        </Routes>
      </div>
    
  );
}

export default App;
