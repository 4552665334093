import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import SubmissionModal from "./SubmissionModal";

function CelebrityForm() {
  const initialFormData = {
    name: '',
    field: '',
    socialMediaLinks: {
      instagram: '',
    },
    source: '',
    stance: 'No statement',
    collection: 'celeb', // Default to 'celeb'
    timestamp: new Date().toISOString(),
  };
  
  const [isModalOpen, setModalOpen] = useState(false); // State to control the modal;
  const [formData, setFormData] = useState({ ...initialFormData });

  console.log(formData)

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Create a copy of the formData
    const updatedFormData = { ...formData };

    // If the name includes a dot (indicating a nested field), handle it correctly
    if (name.includes(".")) {
      const [parentName, childName] = name.split(".");
      updatedFormData[parentName][childName] = value;
    } else {
      updatedFormData[name] = value;
    }

    // Update the state with the new formData
    setFormData(updatedFormData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Send the formData to your server or perform any other actions
    const serverUrl =
      "https://us-central1-palstance-c0189.cloudfunctions.net/api/adminAdd"; // Replace with your actual server URL

    // Define the POST request options
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    };

    console.log(requestOptions);

    // Send the data to the server
    fetch(serverUrl, requestOptions)
      .then((response) => {
        if (response.ok) {
          // Handle a successful form submission, e.g., navigate to a success page

          console.log("Form submitted successfully");
          setModalOpen(true);
          // Clear the form fields by resetting the formData state
          setFormData({ ...initialFormData });
        } else {
          // Handle any errors from the server
          console.error("Form submission failed");
        }
      })
      .catch((error) => {
        console.error("Error sending the form data:", error);
      });
  };

  // Define functions to handle modal actions
  const handleCloseModal = () => setModalOpen(false);
  const handleGoToHome = () => {
    // Close the modal
    handleCloseModal();

    // Navigate to the home page
    navigate("/"); // Update the path as needed
  };

  const handleFillAnother = () => {
    // Clear the form fields by resetting the state to the initialFormData values
    setFormData({
      name: "",
      field: "",
      socialMediaLinks: {
        instagram: "",
      },
      stance: "No statement",
      timestamp: new Date().toISOString(),
    });
    handleCloseModal();
  };

  return (
    <div>
      <h2>Add Data</h2>
      <div>
        <label className="dropdown" htmlFor="collection"></label>
        <select
          id="collection"
          name="collection"
          value={formData.collection}
          onChange={handleChange}
        >
          <option value="celeb">Celebrities</option>
          <option value="brands">Brands</option>
        </select>
      </div>
      <form className="addForm" onSubmit={handleSubmit}>
        <div>
          <label htmlFor="name"></label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Name:"
          />
        </div>

        <div>
          <label htmlFor="field"></label>
          <input
            type="text"
            id="field"
            name="field"
            value={formData.field}
            onChange={handleChange}
            placeholder="Field:"
          />
        </div>

        <div>
          <label htmlFor="instagram"></label>
          <input
            type="text"
            id="instagram"
            name="socialMediaLinks.instagram"
            value={formData.socialMediaLinks.instagram}
            onChange={handleChange}
            placeholder="InstaLink:"
          />
        </div>

        <div>
          <label htmlFor="source"></label>
          <input
            type="text"
            id="source"
            name="source"
            value={formData.source}
            onChange={handleChange}
            placeholder="Source:"
          />
        </div>

        <div>
          <label htmlFor="stance"></label>
          <select
            id="stance"
            name="stance"
            value={formData.stance}
            onChange={handleChange}
          >
            <option value="No statement">No statement</option>
            <option value="Pro Palestine">Pro Palestine</option>
            <option value="Pro Israel">Pro Israel</option>
          </select>
        </div>

        <button type="submit">Submit</button>
      </form>
      {/* Render the SubmissionModal component */}
      <SubmissionModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onGoToHome={handleGoToHome}
        onFillAnother={handleFillAnother}
      />
    </div>
  );
}

export default CelebrityForm;
