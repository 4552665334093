import React, { useState } from 'react';
import './Header.css';
import { Link } from 'react-router-dom';

function Header() {
  const [menuActive, setMenuActive] = useState(false);

  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };
  const closeMenu = () => {
    setMenuActive(false);
  };
  console.log(menuActive)

  return (
    <div>
      <div className='heading'>
        <div className='logo'>
          <Link to="/" className='logoWord'>Stance on Palestine</Link>
        </div>
      {!menuActive && <div className={`rightButtons ${menuActive ? 'active' : ''}`} id='nav-links'>
        <Link to="/add-celebrity" className='uCL' onClick={closeMenu}>
          Update Celebrity List
        </Link>
        <Link to="/brands" className='uCL' onClick={closeMenu}>
          Brands
        </Link>
        <Link to="/admin" className='hidden' onClick={closeMenu}>
          Update admin List
        </Link>
        <Link to="/adminadd" className='hidden' onClick={closeMenu}>
          Update adminadd List
        </Link>
      </div>}
        <div className='menu-icon' id='menu-icon' onClick={toggleMenu}>
          <div className='bar'></div>
          <div className='bar'></div>
          <div className='bar'></div>
        </div>
      </div>
      {menuActive && <div className={`rightButtons ${menuActive ? 'active' : ''}`} id='nav-links'>
        <Link to="/add-celebrity" className='uCL' onClick={closeMenu}>
          Update Celebrity List
        </Link>
        <Link to="/brands" className='uCL' onClick={closeMenu}>
          Brands
        </Link>
        <Link to="/admin" className='hidden' onClick={closeMenu}>
          Update admin List
        </Link>
        <Link to="/adminadd" className='hidden' onClick={closeMenu}>
          Update adminadd List
        </Link>
      </div>}
    </div>
  );
}

export default Header;
